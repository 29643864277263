import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Box, IconButton, InputAdornment, Popover } from "@mui/material";
import CustomTextField from "../Basics/CustomTextField/CustomTextField.tsx";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { formatLocalDate } from "../../utils/UtilFunctions.ts";

import dayjs from "dayjs";

const CustomDatePicker = ({
  defaultValue = null,
  onChange,
  maxToCurrentDate = false,
  minToCurrentDate = false,
  label = "",
  resetSignal,
  initialTitle = "Select Date",
  BoxSx,
}: any) => {
  const [selectedDate, setSelectedDate] = useState<any>(
    defaultValue ? dayjs(defaultValue).toDate() : null
  );

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const today = new Date();

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDate = (event: any) => {
    setSelectedDate(event);
    onChange(formatLocalDate(event, false));
    handleClose();
  };

  const handleDateLimits = () => {
    const res: any = [];
    if (minToCurrentDate) {
      res.push({ before: tomorrow });
    }
    if (maxToCurrentDate) {
      res.push({ after: today });
    }

    return res;
  };

  React.useEffect(() => {
    if (!defaultValue) {
      setSelectedDate(null);
    }
  }, [resetSignal]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const styles: any = {
    "--rdp-accent-color": "#2E7C67",
  };

  return (
    <Box sx={{ ...BoxSx }}>
      <CustomTextField
        aria-describedby={id}
        onClick={handleClick}
        label={label}
        value={
          formatLocalDate(selectedDate, false) === "Invalid Date"
            ? initialTitle
            : formatLocalDate(selectedDate, false)
        }
        variant="standard"
        InputProps={{
          sx: {
            fontWeight: "bold",
            cursor: "pointer",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <CalendarTodayOutlinedIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: 2, bgcolor: "#F2F2F2" }}>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={handleSelectDate}
            captionLayout="dropdown"
            disabled={handleDateLimits()}
            styles={{
              root: styles,
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default CustomDatePicker;
