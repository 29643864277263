import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery } from "@tanstack/react-query";
import { formatLocalDate } from "../UtilFunctions.ts";

const transformPortfolioTable = (response: any) => {
  const data = response.data;
  return data.map((item: any) => ({
    ...item,
    modified_on: formatLocalDate(item.modified_on, false),
  }));
};

const fetchPortfolioTable = async (axiosAuth: AxiosInstance) => {
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}sites/`
  );
  return response;
};

// add enabled by sessionUser here

export function usePortfolioTable() {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["portfolioTable"],
    queryFn: async () => await fetchPortfolioTable(axiosAuth),
    select: (data) => transformPortfolioTable(data),
    refetchOnWindowFocus: false,
  });
}
