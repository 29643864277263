import React, { useEffect, useState } from "react";
import Sidebar from "../../components/layout/Sidebar/Sidebar.tsx";
import Header from "../../components/layout/Header/Header.tsx";
import GenericTable from "../../components/GenericTable/GenericTable.tsx";
import { Box, Typography } from "@mui/material";
import SelectableTags from "../../components/SelectableTags/SelectableTags.tsx";
import {
  initialStates,
  TablesDistribution,
} from "../../features/QualifyingFacility/utils/TablesOptions.tsx";
import useScreenSize from "../../utils/hooks/useScreenSize.ts";
import { useAuth } from "../../context/auth.context.tsx";
import {
  useFullReport,
  usePostReport,
} from "../../utils/api/useFullReportData.ts";
import LoadingStatus from "../../components/LoadingStatus/LoadingStatus.tsx";
import { useSites } from "../../utils/api/useSites.ts";
import { usePortfolios } from "../../utils/api/usePortfolios.ts";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.tsx";
import DownloadFileModal from "../../components/Modals/DownloadFile/DownloadFileModal.tsx";
import { useNotification } from "../../context/notification.context.tsx";
import { isObjectEmptyOrValuesEmptyArrays } from "../../utils/UtilFunctions.ts";
import CustomButton from "../../components/Basics/CustomButton/CustomButton.tsx";
import OptionsButton from "../../components/Basics/OptionsButton/OptionsButton.tsx";

const MainPage: React.FC = () => {
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const { session, logOutAction } = useAuth();
  const { addNotification } = useNotification();
  const [filterParams, setFilterParams] = useState<any>({});
  const [QfForm, setQfForm] = useState<any>({});
  const [analysisType, setAnalysisType] = useState<any>("normal_analysis");
  const [dropDownOptions, setDropDownOptions] = useState<any>({
    sam_id: [],
    name: [],
  });
  const [openDownload, setOpenDownload] = useState(false);
  const [downloadData, setDownloadData] = useState<any>({});
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const handleCloseDownload = () => setOpenDownload(false);
  const handleOpenDownload = () => setOpenDownload(true);

  const {
    data: fullReportData,
    isLoading: isLoadingFullReport,
    isError: isErrorFullReport,
    isFetching: isFetchingFullReport,
  } = useFullReport(session, filterParams, analysisType);

  const {
    data: sitesData,
    isLoading: isLoadingSitesData,
    isError: isErrorSitesData,
  } = useSites(session);

  const {
    data: PortfoliosData,
    isLoading: isLoadingPortfoliosData,
    isError: isErrorPortfoliosData,
  } = usePortfolios(session);

  const postFullReport = usePostReport();

  useEffect(() => {
    if (!isLoadingSitesData && sitesData) {
      const keys = ["sam_id", "name"];
      const dp_options = {};
      keys.forEach((key) => {
        const selectedOptions = sitesData.map((element: any) => element[key]);
        const uniqueArray = [...new Set(selectedOptions)];
        uniqueArray.sort();
        dp_options[key] = uniqueArray;
      });
      setDropDownOptions(dp_options);
    }
  }, [sitesData, isLoadingSitesData]);

  const handleSelectedFilters = (key: any, value: any) => {
    let output = value;
    if (key === "parent" || key === "child") {
      output = value.map((element: any) => element.id);
    }

    if (key === "name") {
      let sites_samids: any = [];
      value.forEach((element: any) => {
        const ids = sitesData
          .filter((item: any) => item.name === element)
          .map((val: any) => val.sam_id);
        sites_samids.push(...ids);
      });
      output = [...sites_samids];
    }

    setQfForm({ ...QfForm, [key]: output });
  };

  const handleRunAnalisys = (type: any) => {
    let id_drop = [];
    let name_drop = [];

    if (QfForm.samid) {
      id_drop = QfForm.samid;
    }
    if (QfForm.name) {
      name_drop = QfForm.name;
    }

    const result = {};

    if (QfForm.parent) {
      result["parent"] = QfForm.parent;
    }

    if (QfForm.child) {
      result["child"] = QfForm.child;
    }

    if (QfForm.name || QfForm.samid) {
      result["samid"] = [...id_drop, ...name_drop];
    }
    setAnalysisType(type);
    setFilterParams(result);
  };

  const handleDownloadAnalisys = async () => {
    handleOpenDownload();
    try {
      setIsLoadingFile(true);
      const ReportData = await postFullReport.mutateAsync({
        filters: filterParams,
        analysis: analysisType,
      });
      if (ReportData?.status === 200) {
        setDownloadData(ReportData.data);
      } else {
        handleCloseDownload();
        addNotification(
          `You can't perform this action, please try again adding some valid filters`
        );
      }
    } catch (e) {
      handleCloseDownload();
      addNotification(
        `You can't perform this action, please try again adding some valid filters`
      );
    }

    setIsLoadingFile(false);
  };

  const handlePortfolioClick = () => {
    navigate("/portfolios");
  };

  if (isLoadingSitesData || isLoadingPortfoliosData) {
    return <LoadingStatus />;
  }

  if (isErrorFullReport || isErrorSitesData || isErrorPortfoliosData) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
        position="fixed"
        top={0}
        left={0}
      >
        <Typography variant="h2">Error Loading Data</Typography>
      </Box>
    );
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Sidebar />
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Header
          text="Qualifying Facility-Distance Analysis"
          onLogout={logOutAction}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m={2}
        >
          {/* Left side: Dropdowns */}
          <Box display="flex" alignItems="center">
            <CustomDropdown
              Title={"SAM ID"}
              Options={dropDownOptions.sam_id}
              loading={isLoadingFullReport}
              onChangeValue={(selection) => {
                handleSelectedFilters("samid", selection);
              }}
            />
            <CustomDropdown
              Title={"Site Name"}
              Options={dropDownOptions.name}
              loading={isLoadingFullReport}
              onChangeValue={(selection) => {
                handleSelectedFilters("name", selection);
              }}
            />
          </Box>

          {/* Right side: Buttons */}
          <Box display="flex" alignItems="center">
            <CustomButton
              sx={{
                textTransform: "none",
                mr: 1,
                paddingY: 1,
              }}
              variant="outlined"
              color="secondary"
              label="Portfolios"
              onClick={handlePortfolioClick}
            />
            <OptionsButton
              disabled={isLoadingFullReport}
              onSelect={(value: any) => {
                handleRunAnalisys(value.value);
              }}
              sx={{
                textTransform: "none",
                paddingY: 1,
                mr: 1,
              }}
              variant="contained"
              color="primary"
              label="Run Analysis"
              options={[
                {
                  label: "1 & 10 Mile Analysis",
                  value: "normal_analysis",
                },
                {
                  label: "20 Mile Analysis",
                  value: "20_mile_analysis",
                },
              ]}
            />

            <CustomButton
              disabled={
                isLoadingFullReport ||
                isObjectEmptyOrValuesEmptyArrays(filterParams)
              }
              sx={{
                textTransform: "none",
                paddingY: 1,
              }}
              variant="contained"
              color="primary"
              label="Download Analysis"
              onClick={() => {
                handleDownloadAnalisys();
              }}
            />
          </Box>
        </Box>

        <SelectableTags
          Title={"SAM ID Source File"}
          Options={PortfoliosData}
          onSelectUpdate={(selection) => {
            handleSelectedFilters("parent", selection);
          }}
        />
        <SelectableTags
          Title={"Subsite Source File(s)"}
          Options={PortfoliosData}
          onSelectUpdate={(selection) => {
            handleSelectedFilters("child", selection);
          }}
        />
        {isLoadingFullReport || isFetchingFullReport ? (
          <Box p={"4px"} m={5}>
            <LoadingStatus />
          </Box>
        ) : (
          <>
            {TablesDistribution(analysisType).map((element, index) => (
              <Box key={index} zIndex={0} p={"4px"} mb={5}>
                <GenericTable
                  title={element.title}
                  maxHeight={"400px"}
                  maxWidth={screenSize.width - 170}
                  columns={element.columns}
                  HeadersFontSize="14px"
                  initialState={initialStates}
                  isLoading={isLoadingFullReport}
                  isFetching={isFetchingFullReport}
                  isError={isErrorFullReport}
                  tableData={fullReportData ? fullReportData[element.ref] : []}
                  rowNumbers={true}
                />
              </Box>
            ))}
          </>
        )}
      </div>
      <DownloadFileModal
        fileName={"Full Report Analysis"}
        open={openDownload}
        onClose={handleCloseDownload}
        Data={downloadData}
        isFile={true}
        isLoading={isLoadingFile}
      />
    </div>
  );
};

export default MainPage;
