import { TextField } from "@mui/material";
import React from "react";

const CustomTextField = ({ sx, ...props }: any) => {
  const customDefaultStyle = {
    "& .MuiInput-root": {
      borderBottom: `2px solid #2E7C67`,
      "&.Mui-active": {
        color: "#2E7C67",
      },
      "&.Mui-disabled": {
        color: "#292929",
        borderBottom: `2px dotted #2E7C67 `,
      },
    },
  };

  return <TextField {...props} sx={{ ...sx, ...customDefaultStyle }} />;
};

export default CustomTextField;
