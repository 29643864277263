import ColumnDateRange, {
  dateRangeFn,
} from "../../../components/GenericComplexTable/CustomColumnFilters/ColumnDateRange.tsx";

export const portfolioTableColumns = [
  {
    accessorKey: "sam_id",
    header: "SAM ID",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorKey: "name",
    header: "Name",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorFn: (originalRow: any) => originalRow.latitude,
    accessorKey: "latitude",
    header: "Latitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.latitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) => originalRow.longitude,
    accessorKey: "longitude",
    header: "Longitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.longitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow.portfolio_name
        ? originalRow.portfolio_name.replace(/_/g, " ")
        : "",
    accessorKey: "portfolio_name",
    enableResizing: true,
    header: "Portfolio Name",
    filterVariant: "select",
    Cell: ({ row }: any) =>
      row.original.portfolio_name
        ? row.original.portfolio_name.replace(/_/g, " ")
        : "",
    enableColumnFilterModes: false,
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow["dc_kw"] ? originalRow["dc_kw"].toLocaleString("en-US") : "",
    accessorKey: "dc_kw",
    header: "DC kW",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["dc_kw"]
        ? row.original["dc_kw"].toLocaleString("en-US")
        : "",
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow["ac_kw"] ? originalRow["ac_kw"].toLocaleString("en-US") : "",
    accessorKey: "ac_kw",
    header: "AC kW",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["ac_kw"]
        ? row.original["ac_kw"].toLocaleString("en-US")
        : "",
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorKey: "docket_id",
    enableResizing: true,
    header: "QF Docket ID",
    filterVariant: "text",
    Cell: ({ row }: any) => {
      const docketId = row.original.docket_id;
      return docketId === null ||
        docketId === undefined ||
        docketId.trim() === ""
        ? ""
        : docketId;
    },
  },
  {
    accessorKey: "modified_on",
    enableResizing: true,
    header: "Modified On",
    enableColumnFilterModes: false,
    Filter: ColumnDateRange,
    filterFn: dateRangeFn,
  },
];
