import { Box, Chip, Paper, Typography } from "@mui/material";
import React, { useState } from "react";

const SelectableTags = ({
  Title,
  Options,
  KeyName = "name",
  onSelectUpdate,
}) => {
  const [selectedTags, setSelectedTags] = useState<any>([]);

  const handleSelection = (value: any) => {
    let selection = selectedTags;
    if (selectedTags.includes(value)) {
      selection = selection.filter((element: any) => element !== value);
    } else {
      selection = [...selection, value];
    }
    setSelectedTags(selection);

    const outSelection = Options.filter((element: any) =>
      selection.includes(element.id)
    );
    onSelectUpdate(outSelection);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        margin: "5px",
        padding: "5px",
        backgroundColor: "#F4F2F2",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          p: "4px",
        }}
      >
        <Typography
          fontSize={"14px"}
          fontWeight="bold"
          sx={{ marginTop: "5px" }}
        >
          {Title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {Options.map((element, index) => (
          <Box
            key={index}
            sx={{
              p: "4px",
            }}
          >
            <Chip
              key={index}
              label={element[KeyName]}
              variant="outlined"
              sx={{
                color: selectedTags.includes(element.id)
                  ? "#FFFFFF"
                  : "#2E7C67",
                backgroundColor: selectedTags.includes(element.id)
                  ? "#2E7C67"
                  : "transparent",
                borderRadius: "5px",
                "&:hover, &.MuiChip-root:hover": {
                  backgroundColor: "#2E7C6733 !important",
                },
              }}
              onClick={() => handleSelection(element.id)}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default SelectableTags;
