import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import CustomDatePicker from "../../CustomDateRange/CustomDatePicker.tsx";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const dateRangeFn = (row: any, columnId: any, filterValue: any) => {
  const cellValue = dayjs(row.getValue(columnId)).startOf("day");

  const { startDate, endDate } = filterValue || {};
  const start = startDate ? dayjs(startDate) : null;
  const end = endDate ? dayjs(endDate) : null;

  if (start && end) {
    return cellValue.isBetween(start, end, "day", "[]");
  } else if (start) {
    return cellValue.isSameOrAfter(start, "day");
  } else if (end) {
    return cellValue.isSameOrBefore(end, "day");
  }
  return true;
};

export const ColumnDateRange = ({ column }: any) => {
  const filterValue = column.getFilterValue() || {};
  const { startDate = "", endDate = "" } = filterValue;
  const [resetDates, setResetDates] = useState(false);

  const handleStartDateChange = (e: any) => {
    const value = e;

    column.setFilterValue((prev: any) => ({
      ...prev,
      startDate: value,
    }));
  };

  const handleEndDateChange = (e: any) => {
    const value = e;

    column.setFilterValue((prev: any) => ({
      ...prev,
      endDate: value,
    }));
  };

  const handleCancel = () => {
    setResetDates((prev) => !prev);

    column.setFilterValue(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      }}
    >
      <CustomDatePicker
        label="Start Date"
        defaultValue={startDate}
        onChange={handleStartDateChange}
        resetSignal={resetDates}
      />
      <CompareArrowsOutlinedIcon fontSize="large" />
      <CustomDatePicker
        label="End Date"
        defaultValue={endDate}
        onChange={handleEndDateChange}
        resetSignal={resetDates}
      />
      <Tooltip title="Clear">
        <IconButton onClick={handleCancel}>
          <HighlightOffOutlinedIcon fontSize="medium" fontWeight={"bold"} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ColumnDateRange;
