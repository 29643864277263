import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export function dynamicSortObjt(property: string, order: string = "asc") {
  const sortOrder = order === "desc" ? -1 : 1;

  return function (a: any, b: any) {
    const aValue = parseFloat(a[property]);
    const bValue = parseFloat(b[property]);

    if (isNaN(aValue) || isNaN(bValue)) {
      // Handle non-numeric cases, defaulting to string comparison
      return a[property] < b[property]
        ? -1 * sortOrder
        : a[property] > b[property]
        ? 1 * sortOrder
        : 0;
    }

    if (aValue < bValue) {
      return -1 * sortOrder;
    }
    if (aValue > bValue) {
      return 1 * sortOrder;
    }
    return 0;
  };
}

export function isObjectEmptyOrValuesEmptyArrays(
  obj: Record<string, any>
): boolean {
  // Check if the object has no keys
  if (Object.keys(obj).length === 0) {
    return true;
  }

  // Check if all values are empty arrays or falsy
  return Object.keys(obj).every((key) => {
    const value = obj[key];
    return Array.isArray(value) ? value.length === 0 : !value;
  });
}

export function formatLocalDate(timestamp: any, specific: boolean = true) {
  let format_struct = "YYYY-MM-DD";
  let res = dayjs.utc(timestamp).format(format_struct);
  if (specific) {
    format_struct = "YYYY-MM-DD hh:mm A";
    res = dayjs.utc(timestamp).local().format(format_struct);
  }

  return res;
}
